import Layout2 from '@/layout/Layout2'

export default [{
  path: '/dashboards',
  meta:{
    title:'仪表盘',
  },
  component: Layout2,
  children: [{
    path: 'dashboard-1',
    meta:{
      title:'工作台',
    },
    component: () => import('@/components/dashboards/Dashboard1')
  }, {
    path: 'dashboard-2',
    meta:{
      title:'工作台',
    },
    component: () => import('@/components/dashboards/Dashboard2')
  }, {
    meta:{
      title:'工作台',
    },
    path: 'dashboard-3',
    component: () => import('@/components/dashboards/Dashboard3')
  }, {
    path: 'dashboard-4',
    component: () => import('@/components/dashboards/Dashboard4')
  }, {
    path: 'dashboard-5',
    component: () => import('@/components/dashboards/Dashboard5')
  }, {
    path: 'dashboard-6',
    component: () => import('@/components/dashboards/Dashboard5')
  }]
}]

// Additional polyfills
import 'custom-event-polyfill'
import 'url-polyfill'

import Vue from 'vue'
import App from './App'
import router from './router'

import BootstrapVue from 'bootstrap-vue'

import globals from './globals'
import functions from './function'
import Popper from 'popper.js'
import vuex from 'vuex'

// Required to enable animations on dropdowns/tooltips/popovers
Popper.Defaults.modifiers.computeStyle.gpuAcceleration = false

Vue.config.productionTip = false

import '@/components/toast/toast.css';
import Toast from '@/components/toast/index';

import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'

import Toasted from 'vue-toasted'

import Viewer from 'v-viewer'
import 'viewerjs/dist/viewer.css'
import Validate from '@/plugs/validate/vee-validate.js'
Vue.use(Validate)

Vue.use(Toasted)
Vue.use(ElementUI)
Vue.use(Viewer)

var opt = {
    defaultType: 'bottom',
    duration: '2500',
    wordWrap: false
  };

Toast.install(Vue, opt)

Vue.use(BootstrapVue)

Vue.use(vuex)

// Global RTL flag
Vue.mixin({
  data: globals,
  methods: functions
})

Vue.prototype.$isEmpty = function(str){
  if(typeof str == "undefined" || str == null || str == ""){
      return true;
  }else{
      return false;
  }
}

var vue = new Vue({
  router,
  render: h => h(App)
}).$mount('#app')

export default vue

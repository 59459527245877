import Blank from '@/layout/LayoutBlank'

export default [{
  path: '/login',
  component: Blank,
  children: [{
    path: 'login',
    component: () => import('@/components/login/login')
  }]
}]

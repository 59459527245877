import Layout2 from '@/layout/Layout2'

export default [{
  path: '/user',
  component: Layout2,
  children: [{
    path: 'index',
    component: () => import('@/components/user/index')
  },{
    path: 'auth',
    component: () => import('@/components/user/auth')
  }]
}]

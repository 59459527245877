export default {

    state: {
        token: localStorage.getItem('token'),
        adminId: localStorage.getItem('adminId'),
    },

    getters: {
        getToken: state => state.token
        ,getAdminId: state => state.adminId
    },

    mutations: {

        //set方法

        setToken(state,token){

            state.token = token
            localStorage.setItem('token', token)
        },
        setAdminId(state,adminId){

            state.adminId = adminId
            localStorage.setItem('adminId', adminId)
        }

    }

}

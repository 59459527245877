import Vue from 'vue'

import Vuex from 'vuex'

import user from '@/store/module/user.js'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    //这里放全局参数
  },
  mutations: {
    //这里是set方法
  },
  getters: {},
  modules: {
    user
  }
})

import Layout2 from '@/layout/Layout2'

export default [{
  path: '/finance',
  component: Layout2,
  children: [{
      path: 'index',
      component: () => import('@/components/finance/index')
    },
    {
        path: 'type',
        component: () => import('@/components/finance/type')
      }
  ]
}]

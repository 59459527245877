import Layout2 from '@/layout/Layout2'

export default [{
  path: '/customer',
  component: Layout2,
  children: [{
    path: 'dashboard-1',
    component: () => import('@/components/customer/Dashboard1')
  },{
    path: 'index',
    component: () => import('@/components/customer/index')
  },{
    path: 'auth',
    component: () => import('@/components/customer/auth')
  }]
}]

import Layout2 from '@/layout/Layout2'

export default [{
  path: '/menu',
  component: Layout2,
  children: [{
      path: 'index',
      component: () => import('@/components/menu/index')
    },
    {
      path: 'modules',
      component: () => import('@/components/menu/modules')
    },
    {
      path: 'basic',
      component: () => import('@/components/menu/basic')
    },
    {
      path: 'notice',
      component: () => import('@/components/menu/notice')
    }
  ]
}]

import Layout2 from '@/layout/Layout2'

export default [{
  path: '/system',
  component: Layout2,
  children: [{
    path: 'home',
    component: () => import('@/components/system/home')
  }]
}]
